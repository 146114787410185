<template>
  <div class="k-word-tags">
    <form @submit.prevent="addTag">
      <k-dropdown v-if="useDropdown" class="options-dropdown"
        :placeholder="dropdownPlaceholder"
        :searchPlaceholder="dropdownSearchPlaceholder"
        :options="options"
        v-model="currentOptionId"
        >
      </k-dropdown>
      <fieldset v-else class="form-entry">
        <label v-if="label != ''" :for="inputId">{{ label }}</label>
        <input :id="inputId" v-model="inputTag" :placeholder="textInputPlaceholder" class="form-control">
      </fieldset>
      <button class="btn btn-primary" @click="addTag" aria-label="Add tag" title="Add tag">
        <i class="remove-button fas fa-plus"></i>
      </button>
    </form>
    <word-tags-list v-model="tags"></word-tags-list>
  </div>
</template>

<style scoped>
.k-word-tags > form .k-search-dropdown-menu {
  width: 90%;
}

.k-word-tags > form .form-entry > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-word-tags > form .form-entry ~ button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-word-tags > form .options-dropdown ~ button {
  margin: 0 5px;
  padding: 12px;
}

.k-word-tags > form {
  display: flex;
  align-items: flex-end;
}

.k-word-tags-submit-form input {
  color: var(--input-background);
  margin-right: 0.5em;
}
</style>

<script>
import KDropdown from '@base-components/k-dropdown.vue';
import WordTagsList from './word-tags-list.vue';

export default {
  components: {
    'k-dropdown': KDropdown,
    'word-tags-list': WordTagsList,
  },
  props: {
    // The selected tags
    modelValue: {
      type: Array,
    },
    // The available options
    options: {
      type: Array,
      required: false,
      default: () => [],
      validator(options) {
        // Each option must have a name and an id
        for (let i = 0; i < options.length; i++) {
          if (!options[i].id || !options[i].name) {
            return false;
          }
        }
        return true;
      },
    },
    dropdownPlaceholder: {
      type: String,
      required: false,
    },
    dropdownSearchPlaceholder: {
      type: String,
      required: false,
    },
    textInputPlaceholder: {
      type: String,
      required: false,
    },
    inputId: {
      type: String,
      default: 'tag-input',
    },
    label: {
      type: String,
      default: 'Tags',
    },
  },

  mounted() {
    this.tags = this.modelValue;
  },

  data() {
    return {
      currentOptionId: undefined,
      inputTag: '',
      tags: [],
    };
  },

  watch: {
    modelValue() {
      this.tags = this.modelValue;
    },
    tags: {
      handler() {
        this.$emit('update:modelValue', this.tags);
      },
      deep: true,
    },
  },

  computed: {
    useDropdown() {
      return this.options.length > 0;
    },
    currentTag() {
      if (this.useDropdown) {
        const t = this.options.find(x => x.id === this.currentOptionId);
        return t ? t.name : undefined;
      }
      return this.inputTag.trim();
    },
  },

  methods: {
    reset() {
      this.currentOptionId = undefined;
      this.inputTag = '';
    },
    addTag() {
      if (!this.currentTag) {
        return;
      }
      if (this.tags.indexOf(this.currentTag) === -1) {
        this.tags.push(this.currentTag);
      }
      this.reset();
    },
  },
};
</script>
