<template>
  <button class="k-icon-button btn-icon"
    :class="classes"
    :aria-label="ariaLabel"
    @click.prevent="$emit('onClick', $event)"
  >
    <i :class="icon"></i>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    classes: {
      type: [String, Array],
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>
