<template>
  <li class="theme-toggle" id="theme-toggle" ref="themeToggle">
    <button @click="handleToggleClick" v-html="selectedThemeDisplay" class="btn-icon"></button>
    <ul class="dropdown" :class="[{ 'is-open': isOpen }]">
      <li v-for="opt in Object.entries(themeOptions)">
        <button type="button" @click="selectTheme(opt[0])" v-html="opt[1]"></button>
      </li>
    </ul>
  </li>
</template>

<style scoped>
.theme-toggle .dropdown {
  min-width: 135px;
}

@media (min-width: 768px) and (max-width: 1084px) {
  #theme-toggle .dropdown {
    left: 50%;
    transform: translate(-54%, 0);
  }

  #theme-toggle .dropdown.is-open {
    left: 50%;
    transform: translate(-54%, 70px);
  }
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1084px) {
  #theme-toggle .btn-icon .fa-caret-down {
    display: none;
  }
}
</style>

<script>
import useThemeStore from '@stores/theme';

export default {
  beforeMount() {
    this.selectedTheme = this.themeStore.theme;
  },

  mounted() {
    this.$survey.init();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  data() {
    return {
      themeStore: useThemeStore(),
      isOpen: false,
      themeOptions: {
        light: '<i class="fa-duotone fa-sun-haze"></i> <span>Light</span>',
        dark: '<i class="fa-duotone fa-moon-stars"></i> <span>Dark</span>',
        legacy: '<i class="fa-duotone fa-building-columns"></i> <span>Legacy</span>',
      },
      selectedTheme: null,
    };
  },

  computed: {
    selectedThemeDisplay() {
      const html = this.themeOptions[this.selectedTheme] || '<i class="fa-duotone fa-eclipse"></i> Theme';
      return `${html} <i class="fas fa-caret-down"></i>`;
    },
  },

  methods: {
    handleToggleClick() {
      this.isOpen = !this.isOpen;
      this.$survey.trackAction('theme_toggle_click');
    },

    selectTheme(theme) {
      this.selectedTheme = theme;
      this.themeStore.theme = this.selectedTheme;
      this.updateFrontendSettings(true);
      this.isOpen = false;
    },

    handleClickOutside(event) {
      const themeToggle = this.$refs.themeToggle;
      if (themeToggle && !themeToggle.contains(event.target)) {
        this.isOpen = false;
      }
    },

    updateFrontendSettings(showToast) {
      return this.$http.put('/api/profile/user/frontend_settings', {
        theme: this.selectedTheme,
        hide_themes_popup: this.themeStore.hideThemesPopup,
        hide_chatbot_popup: this.themeStore.hideChatBotPopup,
      }).then(() => {
        if (showToast) {
          this.$ktoast.success('Theme preference saved');
        }
        this.$logger.info('User preference saved');
      }).catch(err => {
        this.$logger.warn('Error saving user preference for user', err);
      });
    },
  },
};
</script>
