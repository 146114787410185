<template>
  <div id="programme-otj" class="content-with-spacing"  v-if="availableProgrammesReady">
    <div class="heading-container">
      <div class="title-container">
        <h1>
          <i class="fas fa-clock"></i> O.T.J. Logs <b v-if="programmeDetails">- {{ programmeDetails.name }}</b>
        </h1>
      </div>
      <div v-if="availableProgrammes.length > 1" class="header-dropdown">
        <span class="dropdown-label">Programmes:</span>
        <k-dropdown class="programme-dropdown" :options="sortedProgrammes" v-model="programmeIdFromDropdown"></k-dropdown>
      </div>
    </div>

    <div v-if="otjsReady" class="panel panel-default">
      <div class="panel-body">
        <div class="panel-heading">
          <div class="header-left">
            <span>Expected: <b>30h hours</b></span>
            <hr class="vertical-hr">
            <span>Total: <b>{{ otjs.reduce((acc, otj) => acc + otj.total_hours, 0) }} hours</b></span>
          </div>
          <div class="header-right">
            <button class="btn btn-success" @click="showAddNewOtj = true">
              <i class="fas fa-edit"></i> Add new O.T.J.
            </button>
          </div>
        </div>
        <k-table
          @tablecallback="tableCallback"
          :headers="kableHeaders"
          :rows="kableRows"
          :hideable="false"
          :max="10">
        </k-table>
      </div>
    </div>
    <add-new-otj :show-modal="showAddNewOtj" @close="showAddNewOtj = false" :programme-id="programmeIdFromDropdown" />
  </div>
</template>

<style>
#programme-otj th.k-table-header-edit .k-table-header-content {
  justify-content: center;
}
</style>

<style scoped>
.heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
}

.header-dropdown {
  display: flex;
  align-items: center;
  gap: 15px;
}

.panel-heading {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

</style>

<script>
import ProgrammeSelectionMixin from '../mixins/programme-selection-mixin';
import KTable from '../components/k-table.vue';
import KDropdown from '../components/k-dropdown.vue';
import getIcon from '../utils/kate-table-icons';
import AddNewOtj from './otj_components/add-new-otj.vue';

export default {
  components: {
    KDropdown,
    KTable,
    AddNewOtj,
  },

  mixins: [ProgrammeSelectionMixin],

  data() {
    return {
      otjs: [],
      otjsReady: false,
      showAddNewOtj: false,
      showEditModal: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
  },

  watch: {
    programmeIdFromDropdown(newVal) {
      if (newVal !== this.programmeIdFromRoute) {
        this.$router.replace({
          name: 'programme_otj_logs',
          params: { programmeId: newVal },
        });
      }
    },
  },

  computed: {
    routeName() {
      return 'programme_otj_logs';
    },

    kableHeaders() {
      return {
        date: {
          name: 'Date',
          sortable: true,
          type: 'timestamp',
        },
        hours: {
          name: 'Hours',
          sortable: true,
          type: 'number',
        },
        status: {
          name: 'Status',
          sortable: true,
          type: 'string',
        },
        learning_activity_type: {
          name: 'Learning Activity Type',
          sortable: true,
          type: 'string',
        },
        coach_notes: {
          name: 'Coach Notes',
          filterable: true,
          type: 'string',
        },
        tags: {
          name: 'KSBs',
          filterable: true,
          type: 'tags',
        },
        working_hours: {
          name: 'During Working Hours?',
          editable: true,
          type: 'boolean',
          options: {
            true: '<p class="in-use"><i class="fa-solid fa-check"></i></p>',
            false: '<p class="not-in-use"><i class="fa-solid fa-xmark"></i></p>',
          },
        },
        edit: {
          name: 'Edit',
          type: 'action',
          callback: this.editOtjDraft,
        },
      };
    },

    kableRows() {
      return this.otjs.map((otj, index) => ({
        date: otj.updated_at,
        ...otj,
        hours: otj.total_hours,
        status: otj.status,
        learning_activity_type: otj.learning_activity_type,
        coach_notes: otj.coach_notes,
        tags: {
          key: index,
          tags: otj.ksbs || [],
        },
        working_hours: otj.working_hours,
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
      }));
    },
  },

  methods: {
    onProgrammeSelected() {
      if (this.programmeIdFromRoute) {
        this.getOtjLogs();
      }
    },

    getOtjLogs() {
      this.$Loading.start();
      this.otjsReady = false;

      const fakeOtjs = [
        {
          id: 1,
          name: 'OTJ 1',
          description: 'OTJ 1 description',
          created_at: '2024-01-01',
          updated_at: '2024-01-01',
          total_hours: 10,
          status: 'Draft',
          learning_activity_type: 'Edukate E-Learning',
          coach_notes: '',
          ksbs: ['K1', 'K2', 'S1', 'B22'],
          working_hours: true,
        },
        {
          id: 2,
          name: 'OTJ 2',
          description: 'OTJ 2 description',
          created_at: '2024-01-01',
          updated_at: '2024-01-01',
          total_hours: 10,
          status: 'Pending',
          learning_activity_type: 'Edukate E-Learning',
          coach_notes: 'Coach note 1',
          ksbs: ['K1'],
          working_hours: false,
        },
        {
          id: 3,
          name: 'OTJ 3',
          description: 'OTJ 3 description',
          created_at: '2024-01-01',
          updated_at: '2024-01-01',
          total_hours: 10,
          status: 'Declined',
          learning_activity_type: 'Edukate E-Learning',
          coach_notes: '',
          ksbs: ['K1'],
          working_hours: true,
        },
      ];

      this.otjs = fakeOtjs;
      this.otjsReady = true;
      this.$Loading.finish();
    },

    tableCallback(key, index, row, header) {
      if (header === 'edit') {
        this.editOtjDraft(row);
      }
    },

    editOtjDraft() {
      this.showEditModal = !this.showEditModal;
    },
  },
};
</script>
