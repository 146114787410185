<template>
  <k-modal id="add-new-otj-modal" :show="showModal"
    @close="$emit('close')"
    :closeOnClickAway="false"
  >
    <template #header>
      <h3>Add New O.T.J.</h3>
    </template>
    <template #body>
      <div class="modal-body">
        <form class="form-date-picker">
          <fieldset class="form-group form-entry">
            <k-date-picker
              v-model="otjDate"
              label="Date"
              :popover="{ visibility: 'click' }"
              :modelConfig="{ timeAdjust: '00:00:00' }"
            />
          </fieldset>
          <fieldset class="form-group form-entry">
            <k-date-picker
              v-model="otjStartTime"
              label="Start Time"
              type="time"
              mode="time"
              :popover="{ visibility: 'click' }"
              :modelConfig="{ timeAdjust: '00:00:00' }"
            />
          </fieldset>
          <fieldset class="form-group form-entry">
            <k-date-picker
              v-model="otjEndTime"
              label="End Time"
              type="time"
              mode="time"
              :popover="{ visibility: 'click' }"
              :modelConfig="{ timeAdjust: '00:00:00' }"
            />
          </fieldset>
          <fieldset class="form-group form-entry">
            <div class="otj-total-hours-container">
              <label for="otj-total-hours">Total Hours</label>
              <input class="form-control" id="otj-total-hours" type="text" v-model="otjTotalHours" readonly />
            </div>
          </fieldset>
        </form>
        <div class="learning-activity-container">
          <div class="dropdown-container">
            <label>Learning Activity Type <span class="form-required"> *</span></label>
            <k-dropdown
              v-model="otjLearningActivityType"
              :options="learningActivityTypes"
              :show-search="false"
              placeholder="Select Learning Activity Type"
            />
          </div>
          <div class="expandable-container">
            <div class="expandable-header">
              <button class="btn btn-link" @click="toggleExpandableContent" aria-label="Toggle Learning Activity Type">
                <span>Example of OTJ Learning Activity Type<i class="fas" :class="`fa-angle-${showExpandableContent ? 'up' : 'down'}`"></i></span>
              </button>
            </div>
            <div class="expandable-content" v-if="showExpandableContent">
              <ul>
                <li v-for="learningActivityType in learningActivityTypes" :key="learningActivityType.id">
                  <strong>{{ learningActivityType.name }}</strong>
                  <p>{{ learningActivityType.description }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <fieldset class="form-group comment-container">
          <label for="otj-learner-comment">What have you learned that you didn't know before undertaking this activity?</label>
          <textarea id="otj-learner-comment" placeholder="Type your comment here..." v-model="otjLearnerComment" rows="3" />
        </fieldset>
        <div class="competency-container">
          <span class="label-text">Select Competencies relevant to the Learning Activity</span>
          <k-editable-table v-if="competenciesReady"
            v-model="competencies"
            :headers="tableHeaders"
            :max="10"
          />
          <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
          </template>
        </div>
        <div class="confirmation-container">
          <label for="otj-confirmation">
            <input type="checkbox" id="otj-confirmation" v-model="otjConfirmation" />I confirm that all off-the-job training took place during my paid working hours and was directly relevant to my apprenticeship,
            providing new knowledge, skills, or behaviours needed for achievement.
          </label>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="modal-footer-left">
        <button class="btn btn-outlined" @click="$emit('close')">Cancel</button>
      </div>
      <div class="modal-footer-right">
        <button class="btn btn-default" @click="saveAsDraft">Save as Draft</button>
        <button class="btn btn-success" @click="addOtj" :disabled="!validForm">Submit for Review</button>
      </div>
    </template>
  </k-modal>
</template>

<style>
#add-new-otj-modal .modal-footer {
  display: flex;
  justify-content: space-between;
}

#add-new-otj-modal .modal-container {
  width: 80vw;
}

#add-new-otj-modal .form-control[readonly] {
  background-color: var(--input-background);
  border: var(--input-border);
}
</style>

<style scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.form-date-picker {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}

.learning-activity-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.comment-container,
.otj-total-hours-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.comment-container textarea {
  width: 100%;
  min-height: 80px;
  padding: 10px;
  border: var(--input-border-alt);
  border-radius: 4px;
  resize: vertical;
}

.btn-link:focus {
  color: var(--kate-type-link);
  outline-offset: 2px;
}

.btn-link i {
  margin-left: 5px;
}

.expandable-content {
  background-color: var(--kate-background-alt);
  padding: 10px;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0 1px 2px var(--kate-background-alpha);
}

.confirmation-container input {
  margin-right: 5px;
}

.confirmation-container label {
  cursor: pointer;
}

.confirmation-container label:hover {
  text-decoration: underline;
}

.competency-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

i.fas.fa-spinner.fa-spin {
  width: fit-content;
}

</style>

<script>
import KModal from '@base-components/k-modal.vue';
import KDatePicker from '@base-components/k-date-picker.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import KEditableTable from '@base-components/k-editable-table.vue';

const LEARNING_ACTIVITY_TYPES = [
  { id: 1, name: 'E-learning Modules', description: 'Completing an online course on data analysis.' },
  { id: 2, name: 'Workplace/KSB Development', description: 'Participating in training sessions or shadowing a senior colleague to develop job-specific skills.' },
  { id: 3, name: 'Workshop Delivery', description: 'Engaging in practical sessions or hands-on training to build competency in a new tool or methodology.' },
  { id: 4, name: 'Added Value E-learning', description: 'Undertaking self-paced learning through research, video tutorials, or interactive simulations.' },
  { id: 5, name: 'EPA Project Work and Support', description: 'Conducting research, preparing documentation, or compiling evidence for End-Point Assessment (EPA).' },
];

export default {
  components: {
    KModal,
    KDatePicker,
    KDropdown,
    KEditableTable,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    programmeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      date: new Date(),
      otjLearnerComment: '',
      otjDate: new Date(),
      otjStartTime: new Date(),
      otjEndTime: new Date(),
      otjLearningActivityType: '',
      learningActivityTypes: LEARNING_ACTIVITY_TYPES,
      showExpandableContent: false,
      competencies: [],
      competenciesReady: false,
      otjConfirmation: false,
    };
  },

  beforeMount() {
    this.getCompetencies();
  },

  watch: {
    showModal(newVal) {
      if (newVal) {
        this.getCompetencies();
      }
    },
  },

  computed: {
    validForm() {
      return this.otjConfirmation;
    },

    otjTotalHours() {
      if (!this.otjStartTime || !this.otjEndTime) {
        return '';
      }

      if (this.otjEndTime < this.otjStartTime) {
        return 'Invalid time range';
      }

      const diffMs = this.otjEndTime - this.otjStartTime;

      const diffMinutes = Math.floor(diffMs / 1000 / 60);
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;

      return `${hours}h ${minutes}m`;
    },

    timeSpentMinutes() {
      if (!this.otjStartTime || !this.otjEndTime) {
        return 0;
      }

      if (this.otjEndTime < this.otjStartTime) {
        return 0;
      }

      const diffMs = this.otjEndTime - this.otjStartTime;
      return Math.floor(diffMs / 1000 / 60); // total minutes
    },

    tableHeaders() {
      return [
        {
          key: 'checkbox',
          title: '',
          editable: true,
          type: 'boolean',
          required: true,
          sortable: false,
        },
        {
          key: 'label',
          title: 'Label',
          editable: false,
          sortable: true,
          filterable: true,
          type: 'string',
        },
        {
          key: 'category',
          title: 'Category',
          editable: false,
          sortable: true,
          filterable: true,
          type: 'string',
        },
        {
          key: 'description',
          title: 'Description',
          editable: false,
          sortable: true,
          filterable: true,
          type: 'string',
        },
      ];
    },
  },

  methods: {
    getCompetencies() {
      this.$logger.info('Fetching competencies', true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/competencies/scores`)
        .then(response => {
          this.competencies = response.data.competencies;
          this.competenciesReady = true;
        })
        .catch(error => {
          this.$logger.error('Error fetching competencies', error);
          this.competenciesReady = true;
        });
    },

    toggleExpandableContent() {
      this.showExpandableContent = !this.showExpandableContent;
    },
    saveAsDraft() {
      throw new Error('Not Implemented');
    },
    addOtj() {
      throw new Error('Not Implemented');
    },
  },
};

</script>
